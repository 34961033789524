import './App.css';
import Calculation from './Calculation';

function App() {
  return (
    <Calculation/>
  );
}

export default App;
